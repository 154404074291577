import axios from "axios";
import { AdApplication, TokenReponse } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/AdApplications';

const AdApplicationsApi = {
    get: async (filter?: any, page?: number): Promise<AdApplication[]> => {
        filter.page = page;
        let url = `${baseUrl}?${new URLSearchParams(filter).toString()}`;
        const response = await axios.get(url);
        return response.data;
    },
    getAccessToken: async (clientId: string): Promise<TokenReponse> => {
        const response = await axios.get(`${baseUrl}/${clientId}/accesstoken`);
        return response.data;
    },
    getClientSecret: async (clientId: string): Promise<string> => {
        const response = await axios.get(`${baseUrl}/${clientId}/clientsecret`);
        return response.data;
    }
};

export default AdApplicationsApi;