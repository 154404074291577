import { IcebergAlert, IcebergIcon, IconButton, useSelectedTsKunde } from '@tradesolution/iceberg-ui-react';
import { useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import SecretModal from './SecretModal';
import TestModal from './TestModal';
import AdApplicationsApi from '../../Api/AdApplicationsApi';
import { AdApplication } from '../../Api/AdApplicationsApi/types';

const AdApplications = () => {

    const [applications, setApplications] = useState<AdApplication[]>([]);

    const [selectedApp, setSelectedApp] = useState<AdApplication | undefined>();
    const { selectedTsKunde } = useSelectedTsKunde();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showTestModal, setShowTestModal] = useState(false);
    const [show, setShow] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [filter, setFilter] = useState({} as any);

    const loadApplications = async () => {
        setIsLoading(true);
        const result = await AdApplicationsApi.get(filter, 1);
        setApplications(result);
        setIsLoading(false);
    };

    useEffect(() => {
        if (filter.tsKundeId) {
            loadApplications();
        }
    }, [filter]);


    useEffect(() => {
        if (selectedTsKunde) {
            setFilter({ tsKundeId: selectedTsKunde.tsKundeId });
        }
    }, [selectedTsKunde?.tsKundeId]);

    const handleTestAppClicked = (app: AdApplication) => {
        setShow(undefined);
        setShowTestModal(true);
        setSelectedApp(app);
    };

    const handleEditAppClicked = (app: AdApplication) => {
        setShowEditModal(true);
        setSelectedApp(app);
    };

    const handleCloseTestModal = () => {
        setSelectedApp(undefined);
        setShowTestModal(false);
    }

    const handleCloseEditModal = () => {
        setSelectedApp(undefined);
        setShowEditModal(false);
        loadApplications();
    }

    const toogleOverlayMenu = (app?: AdApplication, nextShow?: boolean) => {
        if (nextShow === true)
            setShow(app?.applicationId);
        if (nextShow === false && show === app?.applicationId)
            setShow(undefined);
    };

    return (
        <div className='container-fluid'>
            <Row style={{ marginTop: "0.5rem" }}>
                <Col className="mb-3 mt-3">
                    <h2 className='fw-bold' style={{ fontSize: '20px', textAlign: 'left' }}>API tilganger</h2>
                </Col>
            </Row>
            {(applications.length === 0 && !isLoading) && (
                <IcebergAlert variant="info">
                    Du har ikke tilgang til en eneste client eller secret. Ta kontakt med support@tradesolution.no hvis du mener at det er feil
                </IcebergAlert>
            )}
            {isLoading && (
                <Row>
                    <Col>
                        <span>Henter applikasjoner...</span>
                    </Col>
                </Row>
            )}

            {(applications.length > 0) && (
                <Row>
                    <Col>

                        <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                            <thead>
                                <tr>
                                    <th>Eier</th>
                                    <th>Client</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                                {applications.map((a, i) => <tr key={a.objectId}>
                                    <td>{a.tsKundeNavn} {a.tilgangerOnBehalfOfTsKunde ? `(på vegne av ${a.tilgangerOnBehalfOfTsKunde})` : ''}</td>
                                    <td>{a.name}</td>
                                    <td className="fitContent">
                                        <Button variant='outline-primary' onClick={() => handleEditAppClicked(a)}>
                                            Se client id og secret
                                        </Button>
                                    </td>
                                    <td className="fitContent">
                                        <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show === a.applicationId} onToggle={(nextShow) => toogleOverlayMenu(a, nextShow)}
                                            overlay={
                                                <Popover id={`popover-positioned-${'left'}`}>
                                                    <Popover.Body>
                                                        <IconButton variant='outline-primary' icon="send" style={{ width: '100%', border: '0px' }} onClick={() => handleTestAppClicked(a)}>
                                                            Test
                                                        </IconButton>
                                                    </Popover.Body>
                                                </Popover>
                                            }>
                                            <button style={{ border: 'none', background: 'transparent', padding: '0.5rem' }}>
                                                <IcebergIcon icon="ellipsis" color='#68778D' />
                                            </button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            <SecretModal show={showEditModal} application={selectedApp} onClose={handleCloseEditModal} />
            <TestModal show={showTestModal} application={selectedApp} onClose={handleCloseTestModal} />
        </div>

    )
};

export default AdApplications;