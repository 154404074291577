import { VerifyResultDto } from "./types";

import axios from "axios";


const EmailsApi = {
    verify: async (email: string): Promise<VerifyResultDto> => {
        const response = await axios.get(`https://adminportalenapi.tradesolution.no/api/Emails/${email}/Verify`);
        return response.data;
    }    
};


export default EmailsApi;