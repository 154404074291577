export const msalConfig = {
    auth: {
        clientId: "3e8e2593-af4f-4927-8bf1-39785840e925", // TilgangerSpaPkce
        authority: "https://login.microsoftonline.com/trades.no", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://trades.no/TradesolutionApi/user_impersonation"]
};