import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Layout from "./Layout";
import './Layout.scss';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import AdApplications from "./Pages/Applikasjoner";
import Brukere from "./Pages/Brukere";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';


LogRocket.init('msbcwv/tilganger');
setupLogRocketReact(LogRocket);
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Brukere />} />
            <Route path="Clients" element={<AdApplications />} />  
          </Route>          
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
