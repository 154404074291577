import axios from "axios";

import {
  BrukerWithTilgangerDto, BrukerSearchFilter, CreateBrukerCommand, UpdateBrukerCommand, UpdateBrukerTilgangerCommand, BrukerDto, AccessToTypeDto, RoleTypeDto, BrukerStorageLocationStatusDto
} from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/Brukere';

const BrukereApi = {
  getBrukereWithPermissions: async (filter: BrukerSearchFilter, page: number): Promise<BrukerWithTilgangerDto[]> => {
    filter.page = page;
    const response = await axios.post(`${baseUrl}/WithPermissions`, filter);
    return response.data;
  },

  getBrukereCount: async (filter: BrukerSearchFilter): Promise<number> =>
    axios.post(`${baseUrl}/Count`, filter).then(response => response.data),

  createBruker: async (command: CreateBrukerCommand): Promise<BrukerDto> =>
    axios.post(baseUrl, command).then(response => response.data),

  updateBruker: async (command: UpdateBrukerCommand): Promise<BrukerDto> =>
    axios.put(baseUrl, command).then(response => response.data),

  updateBrukerTilgangerOnBehalfOf: async (command: UpdateBrukerTilgangerCommand) =>
    axios.put(`${baseUrl}/TilgangerOnBehalfOf`, command).then(response => response.data),  
  
  getAccessTos: async (): Promise<string[]> =>
    axios.get(`${baseUrl}/AccessTo`).then(response => response.data),

  getRoles: async (): Promise<string[]> =>
    axios.get(`${baseUrl}/Role`).then(response => response.data),

  search: async (value: string): Promise<BrukerDto[]> =>
    axios.get(`${baseUrl}/Search?searchValue=${value}`).then(response => response.data),

  checkStorageLocationStatus: async (email: string): Promise<BrukerStorageLocationStatusDto[]> =>
    axios.get(`${baseUrl}/CheckStorageLocationStatus?email=${email}`).then(response => response.data),

  getAccessToTypes: async (): Promise<AccessToTypeDto[]> =>
    axios.get(`${baseUrl}/AccessToTypes`).then(response => response.data),

  getRoleTypes: async (): Promise<RoleTypeDto[]> =>
    axios.get(`${baseUrl}/RoleTypes`).then(response => response.data),  
};

export default BrukereApi;