import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { IcebergProductMenu, useSelectedTsKunde, IcebergIcon, IcebergCustomerPicker, IcebergNotifications } from '@tradesolution/iceberg-ui-react';
import { useEffect } from "react";
import LogRocket from "logrocket";


const Menu = () => {

    const { instance, accounts } = useMsal();
    const { clearSelectedTsKunde } = useSelectedTsKunde();


    const name = accounts[0] && accounts[0].name;

    const getFirstName = (fullname: string | undefined): string => {
        if (!fullname) {
            return "";
        }
        return fullname.split(' ')[0];
    };

    const handleUserLoggedOut = () => {
        clearSelectedTsKunde();
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    }

    // for logrocket 
    useEffect(() => {
        LogRocket.identify(name);
    }, [name]);
    return (
        <Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }} >
            <Container fluid>
                <Navbar.Brand href="/">
                    <IcebergIcon icon='key' />
                    <span style={{ marginLeft: '0.5em' }}>Tilganger</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse id="navigation">
                    <Nav className="me-auto">
                        <NavDropdown
                            title={<IcebergIcon icon='meatballs' />}
                            className='noCaret'>
                            <IcebergProductMenu />
                        </NavDropdown>
                    </Nav>

                    <Nav style={{ marginLeft: "auto" }}>
                        <NavDropdown
                            id="dropdownUserMenu"
                            align="end"
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                                </div>
                            }
                        >
                            <NavDropdown.Item
                                onClick={() => handleUserLoggedOut()}
                            >
                                <span><strong>Logg av</strong></span>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <IcebergCustomerPicker />
                        <IcebergNotifications />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;
