import { IconButton } from '@tradesolution/iceberg-ui-react';
import { BrukerTilgangCommand } from '../../../../Api/BrukereApi/types';
import AccessToSelect from '../AccessToSelect';
import RoleSelect from '../RoleSelect';

interface Props {
  permission: BrukerTilgangCommand;
  onUpdateAccessTo: (value: string | undefined) => void;
  onUpdateRole: (value: string | undefined) => void;
  onDeleteRow: () => void;
  existingAccessTos?: string[];
}

const TilgangRow = (props: Props) => {
 
  return (
    <tr>      
      <td>
        <AccessToSelect
          size='sm'
          selected={props.permission.accessTo || ''}
          onChange={a => props.onUpdateAccessTo(a)} 
          existingAccessTos={props.existingAccessTos}/>
      </td>
      <td>
        <RoleSelect
          size='sm'
          selected={props.permission.role || ''}
          onChange={r => props.onUpdateRole(r)} />
      </td>
      <td className='fitContent p-0'>
        <IconButton icon='trash' variant='outline-danger' onClick={props.onDeleteRow} />
      </td>
    </tr>
  )
};

export default TilgangRow;