import { IconButton } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { AdApplication } from "../../Api/AdApplicationsApi/types";
import useCopyToClipboard from "../../utils/hooks/useCopyToClipboard";
import AdApplicationsApi from "../../Api/AdApplicationsApi";

interface Props {
    show: boolean;
    onClose: () => void;
    application?: AdApplication;
}

enum SecretStatus {
    NotLoaded, Loading, Loaded, NoAccess, Missing
}

const SecretModal = (props: Props) => {

    const [value, copy] = useCopyToClipboard();

    const [secretStatus, setSecretStatus] = useState<SecretStatus>(SecretStatus.NotLoaded);

    const loadClientSecret = async () => {
        if (props.application) {
            try {
                setSecretStatus(SecretStatus.Loading);
                const secret = await AdApplicationsApi.getClientSecret(props.application.applicationId);
                if (!secret) {
                    setSecretStatus(SecretStatus.Missing);
                } else {
                    setSecretStatus(SecretStatus.Loaded);
                }
                setClientSecret(secret);
            } catch (e) {
                setSecretStatus(SecretStatus.NoAccess);
            }
        } else {
            setSecretStatus(SecretStatus.NotLoaded);
            setClientSecret(undefined);
        }
    };

    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [isSecretHidden, setIsSecretHidden] = useState(true);

    const togglePasswordVisibility = () => {
        setIsSecretHidden(!isSecretHidden);
    };

    const [name, setName] = useState<string | undefined>(props.application?.name || '');
    const handleNameChanged = (value: string) => {
        setName(value);
    };

    const [onBehalfOftsKunde, setOnBehalfOfTsKunde] = useState<string | undefined>(props.application?.tilgangerOnBehalfOfTsKunde)    

    // reset component state when application changes
    useEffect(() => {
        setIsSecretHidden(true);
        loadClientSecret();
        setName(props.application?.name);
        setOnBehalfOfTsKunde(props.application?.tilgangerOnBehalfOfTsKunde);
    }, [props.application]);

    const close = async () => {
        props.onClose();
    }

    return (
        <Modal show={props.show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Client id og secret
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>                    
                    <Form.Group className="mb-3">
                        <Form.Label>Client id</Form.Label>
                        <InputGroup>
                            <Form.Control disabled type="text" value={props.application?.applicationId} />
                            <IconButton variant="outline-primary" title="Kopier til utklippstavle" icon="copy-to-clipboard" onClick={() => copy(props.application?.applicationId)} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Client secret</Form.Label>
                        <InputGroup>
                            <Form.Control disabled type={isSecretHidden ? "password" : "text"} value={clientSecret} />
                            <IconButton variant="outline-primary" disabled={secretStatus != SecretStatus.Loaded} icon="eye-open" title="Secret vises kun om du har riktige tilganger" onClick={togglePasswordVisibility} />
                            <IconButton variant="outline-primary" disabled={secretStatus != SecretStatus.Loaded} title="Kopier til utklippstavle" icon="copy-to-clipboard" onClick={() => copy(clientSecret)} />
                        </InputGroup>
                        <Form.Text className="error-text">
                            {secretStatus === SecretStatus.NoAccess && 'Du mangler rettigheter til å se client secret'}
                            {secretStatus === SecretStatus.Missing && 'Client secret mangler i KeyVault'}
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <IconButton variant="outline-primary" icon="close" className="col" onClick={close}>
                    Lukk
                </IconButton>                
            </Modal.Footer>
        </Modal >
    );
};

export default SecretModal;