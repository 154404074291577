import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ValueDisplaynameDto } from "../../../../Api/PermissionsApi/types";
import PermissionsApi from "../../../../Api/PermissionsApi";

interface Props {
  selected: string | undefined;
  onChange: (role: string | undefined) => void;
  disabled?: boolean;
  size?: "sm" | "lg";
  style?: React.CSSProperties;
}

const RoleSelect = (props: Props) => {
  const [roles, setRoles] = useState<ValueDisplaynameDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadRoles = async () => {
    setLoading(true);
    const res = await PermissionsApi.getRoles();
    setRoles(res.filter(x => x.displayName === "Superbruker"));
    setLoading(false);
  };

  const handleChange = (value: string | undefined) => {
    props.onChange(value);
  }

  useEffect(() => {
    loadRoles();
  }, []);

  { loading && <Spinner animation="border" /> }

  return (
    <Form.Select
      size={props.size}
      aria-label="RoleSelect"
      style={{ ...props.style, maxWidth: props.style?.width ? undefined : '250px' }}
      onChange={e => handleChange(e.target.value)} value={props.selected ?? ""} disabled={props.disabled}
    >
      <option value="">Velg rolle</option>
      {
        roles.map(x =>
          <option key={x.value} value={x.value}>{x.displayName}</option>
        )
      }
    </Form.Select>
  );
};

export default RoleSelect;