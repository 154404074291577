import { Outlet } from "react-router";
import Menu from "./Components/Menu";
import '@tradesolution/iceberg-styles/dist/css/iceberg.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import EnsureAuthToken from "./utils/auth/EnsureAuthToken";
import { Row } from "react-bootstrap";
import SidebarWrapper from "./Components/SidebarWrapper";

const Layout = () => {

    // redirect to login if not authenticated
    useMsalAuthentication(InteractionType.Redirect);

    return (
        <div>
            <AuthenticatedTemplate>
                <EnsureAuthToken>
                <>
                    <Menu />
                        <div className="container-fluid">
                            <Row className="flex-nowrap">
                                <SidebarWrapper />
                                <main className="col">
                                    <Outlet />
                                </main>
                            </Row>
                        </div>
                </>
                </EnsureAuthToken>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Redirecting to login...</p>
            </UnauthenticatedTemplate>
        </div>);
}

export default Layout;