import { Permission, ValueDisplaynameDto } from "./types";

import { getBaseUrl } from "..";
import axios from "axios";

const baseUrl = getBaseUrl() + '/Permissions';

const PermissionsApi = {
    getAccesses: async (): Promise<ValueDisplaynameDto[]> => {
        const response = await axios.get(`${baseUrl}/accesses`);
        return response.data;
    },
    getRoles: async (): Promise<ValueDisplaynameDto[]> => {
        const response = await axios.get(`${baseUrl}/roles`);
        return response.data;
    },
    getByObjectId: async (objectId: string, tsKundeId: string): Promise<Permission[]> => {
        const response = await axios.get(`${baseUrl}/${objectId}?onlyKeepTsKundeId=${tsKundeId}`);
        return response.data;
    },
    getMyPermissions: async (tsKundeId: string): Promise<Permission[]> => {
        const response = await axios.get(`${baseUrl}/me?onlyKeepTsKundeId=${tsKundeId}`);
        return response.data;
    }
};

export default PermissionsApi;