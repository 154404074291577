import { Col, Row } from "react-bootstrap";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { BrukerWithTilgangerDto, TilgangDto } from "../../../../Api/BrukereApi/types";
import { getAppIcon } from "../../../../Components/IcebergIcon/utils";

interface Props {
  bruker: BrukerWithTilgangerDto;
  filterOnTsKundeId: string;
}

const BrukerTilgangerDropdown = (props: Props) => {

  const getDistinctAppNames = (tilgang: TilgangDto[]): string[] => {    
    const appNames: string[] = [];
    tilgang
    .filter(x => x.tsKundeId === props.filterOnTsKundeId.toLowerCase())
    .flatMap(o => o.apps)
    .forEach(app => {
      if (!appNames.includes(app.appName)) {
        appNames.push(app.appName);
      }
    });    
    return appNames;
  };

  return (
    <div>
      <Row>        
        <Col>
          <span className="text-nowrap">
            {props.bruker.accessCount > 0 &&
              getDistinctAppNames(props.bruker.tilganger)
                .sort((a, b) => a.localeCompare(b))
                .map((app) => (
                  <IcebergIcon
                    key={Math.random()}
                    icon={getAppIcon(app)}
                    title={app}
                  />
                ))
            }
          </span>
        </Col>
        <Col>
          { props.bruker.tilganger
          .filter(x => x.tsKundeId === props.filterOnTsKundeId.toLowerCase())
          .some(x => x.apps.some(y => y.accesses.some(z => z.roleDisplayName === "Superbruker"))) ?
            "Superbruker" : ""}
        </Col>
      </Row>
    </div>
  );
};

export default BrukerTilgangerDropdown;