import { useRef, useEffect } from 'react';
export default function useIsMountedRef() {
  const isMountedRef = useRef<any>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}