

export const getTjenestegruppeIcon = (tjenestegruppeNavn: string) => {
    switch (tjenestegruppeNavn.toLowerCase()) {
      case 'epd':
        return 'epd';
      case 'mediastore':
        return 'mediastore';
      case 'fotostudio':
        return 'fotostudio';
      case 'increase':
      case 'tradefacts':
      case 'insight':
        return 'suite';
      case 'sjekkpunkt':
      case 'orion':
        return 'orion';
      case 'kjederegisteret':
        return 'kradmin';
      case 'salgsdata':
        return 'sell';
      case 'edison':
        return 'edison';
      case 'intrack':
        return 'chart-pie';
      case 'bridge':
        return 'bridge';
    }
    return 'takecargo';
  }
  
  // sell = salgsdata
  // intrack = chart-pie
  // insight blir kalt increase i iceberg-ui-react
  // increase ikon = suite i iceberg-ui-react
  export const getAppIcon = (tjenestegruppeNavn: string) => {
    switch (tjenestegruppeNavn.toLowerCase()) {
      case 'admin.jobberogdistribusjon':
        return 'sell';
      case 'adminportal':
        return 'adminportal';
      case 'bridge':
      case 'bridge.admin':
        return 'bridge';
      case 'edison':
        return 'edison';
      case 'epd':
        return 'epd';
      case 'epdadmin':
      case 'epdadminportal':
        return 'epdadmin';
      case 'epdbedrift':
      case 'epdbedrift.reseptmanager':
      case 'epdbedrift.reseptqa':
      case 'coopresept':
        return 'coopresept';
      case 'epicfail':
        return 'recall';
      case 'recall':
        return 'recall';
      case 'global':
        return 'globe';
      case 'increase.api':
      case 'increase':
      case 'instore':
      case 'instore.dokument':
      case 'instore.kredit':
      case 'instore.ordre':
        return 'suite';
      case 'intrackadmin':
      case 'intrack.admin':
      case 'management':
        return 'management';
      case 'intrack':
      case 'intrack.grossistvaregrupperapport':
      case 'intrack.horecamarkedsrapportrapportmnd':
      case 'intrack.horecastrukturrapport':
      case 'intrack.horecavaregrupperapport':
      case 'intrack.iskrem':
      case 'intrack.kbsmarkedsrapportrapportmnd':
      case 'intrack.kbsstrukturrapport':
      case 'intrack.kbsvaregrupperapport':
      case 'intrack.kundeanalyse':
        return 'chart-pie';
      case 'kjederegisteret':
      case 'kjederegisteret.endringsmeldinger':
      case 'kjederegisteretadmin':
        return 'kradmin';
      case 'kolly':
        return 'cooperation';
      case 'kundeportal':
        return 'startsiden';
      case 'lanseringsprognoser':
        return 'lanseringer';
      case 'mediastore':
      case 'mediastore.cdn':
      case 'mediastore.fulltilgang':
      case 'mediastore.msadmin':
      case 'mediastore.privatearkiver':
      case 'mediastore.qa':
        return 'mediastore';
      case 'orion':
        return 'orion';
      case 'pisk':
        return 'coopportal';
      case 'posdata':
      case 'posdatateknisk':
      case 'salgsapi':
      case 'salgsdata':
        return 'sell';
      case 'takecargo':
        return 'takecargo';
      case 'insight':
      case 'tradefacts':
      case 'tradefacts.fulltilgang':
      case 'insight.lesetilgang':
      case 'insight.fulltilgang':
      case 'insight.kansealleselgere':
        return 'suite';
      case 'filer':
      case 'tsdataexport':
        return 'filer';
      case 'vetduatapi':
      case 'vetduat':
        return 'vetduat';
      case 'coopportal':
        return 'coopportal';
      case 'startsiden':
        return 'startsiden';
      case 'servicegrossisteneportalen':
        return 'cooperation';
      case 'synergy':
        return 'cooperation';
    }
    return 'question';
  }