import Sidebar, { NavLink } from "./Sidebar";


const SidebarWrapper = () => {
    const navLinks: NavLink[] = [
        {label: 'Bruker tilganger', link: '/', icon: 'users'},
        {label: 'API tilganger', link: '/Clients', icon: 'cards'},
    ];


    return <Sidebar navLinks={navLinks} />
};

export default SidebarWrapper;