export interface Access {
    id: string;
    role: string;
    accessTo: string;
}

export interface App {
    appName: string;
    accesses: Access[];
}

export interface Tilganger {
    tsKundeId: string;
    tsKundeNavn: string;
    apps: App[];
}

export interface AdApplication {
    objectId: string;
    applicationId: string;
    name: string;
    deactivatedDate?: Date;
    dateCreated: Date;
    oppdatertAv: string;
    tsKundeId?: string;
    tsKundeNavn?: string;
    accessCount: number;
    roleCount: number;
    tilgangerOnBehalfOfTsKundeId?: string;
    tilgangerOnBehalfOfTsKunde?: string;
    tilganger: Tilganger[];    
}

export interface Tilgang {
    id?: string;
    role?: string;
    accessTo: string;
}

export enum TokenReponseStatus {
    MissingInKeyVault, NoAccess, Success, UnknownApplication
}

export interface TokenReponse {
    accessToken: string;
    responseStatus: TokenReponseStatus;
}
